<template>
    <div>
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <el-button type="primary" size="small" @click="addBtn()" plain>添加职位</el-button>
                </div>
                <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="userPostName" label="职位名称">
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <span class="info optionBtn" @click="editBtn(scope.row)">编辑</span>
                            <span class="danger optionBtn" @click="removeBtn(scope.row,scope.$index)">删除
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!--    Dialog 新增/编辑    -->
        <el-dialog :title="dialogTitle" :visible.sync="addDialog" width="500px">
            <el-form class="dialog-form" :model="formData" ref="formDataRef" :rules="rules" :label-width="formLabelWidth">
                <el-form-item label="职位名称" prop="userPostName">
                    <el-input v-model="formData.userPostName" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog('formDataRef')">取 消</el-button>
                <el-button type="primary" @click="saveData('formDataRef')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { UserPost } from "@/components/HospitalDomain/UserPost.js";
import bread from "@/components/common/breadcrumb"
import { userInfo } from "os";
export default {
    name: "department",
    components: {
        bread
    },
    data() {
        var userPost = new UserPost(this.TokenClient, this.Services.Authorization);
        return {
            userPostDomain: userPost,
            tableData: [],
            addDialog: false,
            dialogTitle: '新增职位',
            userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
            formData: {
                id:0,
                userPostName:'',
                productId:0
            },
            formLabelWidth: '120px',
            rules: {
                userPostName: [{ required: true, message: '请输入职位名称', trigger: 'blur' },],
            },
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        addBtn() {
            this.formData = {
                id:0,
                userPostName:'',
                productId:this.userInfo.productId
            }
            this.addDialog = true;
            this.dialogTitle = '新增职位';
        },
        editBtn(item) {
            this.formData = {
                id:item.id,
                userPostName:item.userPostName,
                productId:item.productId
            }
            this.addDialog = true;
            this.dialogTitle = '编辑职位';
        },
        saveData(formName) {
            var _this = this;
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (_this.formData.id) {
                        _this.userPostDomain.editUserPost(_this.formData,
                            function (data) {
                                _this.$message({
                                    type: 'success',
                                    message: '操作成功!'
                                });
                                _this.getList()
                                _this.addDialog = false;
                            },
                            function (err) { console.log(err); });
                    } else {
                        _this.userPostDomain.addUserPost(_this.formData,
                            function (data) {
                                _this.$message({
                                    type: 'success',
                                    message: '操作成功!'
                                });
                                _this.getList()
                                _this.addDialog = false;
                            },
                            function (err) { console.log(err); });
                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        removeBtn(e, index) {
            var _this = this;
            _this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                // type: 'warning'
            }).then(() => {
                _this.userPostDomain.removeUserPost(e.id,
                    function (data) {
                        _this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        _this.tableData.splice(index, 1)
                    },
                    function (err) { console.log(err); });

            }).catch(() => {

            });

        },
        closeDialog() {
            var _this = this;
            _this.addDialog = false;
        },
        getList() {
            var _this = this;
            _this.userPostDomain.GetUserPost(this.userInfo.organizationId,
                function (data) {
                    _this.tableData = data.data;
                },
                function (err) { console.log(err); });
        },
    }
}
</script>

<style scoped>
.dialog-form {
    width: 430px;
}

.table-top {
    margin-bottom: 15px;
}
</style>
